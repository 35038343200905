import React from 'react';
import { NavItem } from '../../utils/types';
import Button from '../../components/button';
import './thank-you.css';

export interface ThankYoutProps {
  number?: string;
  headerText?: string;
  headerTextMain?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const ThankYou: React.FC<ThankYoutProps> = ({
  headerText = 'WE APPRECIATE YOUR VALUABLE FEEDBACK,',
  headerTextMain = 'Thank you!',
  style
}) => {
  return (
    <div id="Loyalty" style={style}>
      <div className="Loyalty-content">
        <div className="Loyalty-inner-content">
          <div className="Loyalty-title">
            <div className="Loyalty-title__content">
              <h3 className="Loyalty-title__content-top">{headerText}</h3>
              <h1 className="Loyalty-title__content-main">{headerTextMain}</h1>
            </div>
          </div>
          <div className="Loyalty-details">
            <p>Have a fin-tastic day!</p>
            <div className="Loyalty-actions">
              <a href="https://finswash.com/">
                <Button text="Finswash.com" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
