import React from 'react';
import { useMedia } from '../utils/hooks';
import SEO from '../components/seo';
import ThankYou from '../sections/thank-you';
import Footer from '../components/footer';
import FinsWashIcon from '../components/icons/finswash';
import { Link } from 'gatsby';

import '../styles/promo-page.css';

function noop() {
  return;
}

const PromoPage: React.FC<any> = () => {
  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)', '(min-width: 0px)'],
    [
      { width: '206', height: '40' },
      { width: '249.18', height: '47.54' },
      { width: '166.12', height: '31.69' }
    ],
    { width: '206', height: '40' }
  );

  return (
    <div id="PromoTemplate" className="Promo-container">
      <SEO title="Fins: Thank You" />
      <svg
        className="PromoTemplate__background-img"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="1%" stopColor="#649A30" />
            <stop offset="80%" stopColor="#154288" />
          </linearGradient>
        </defs>
        <polygon fill="url(#gradient)" points="0 0 10 0 0 10" />
      </svg>
      <div className="Promo-container__header">
        <Link to="/">
          <FinsWashIcon color="#16478E" {...iconSize} />
        </Link>
      </div>
      <ThankYou />
      <Footer items={[]} onNavItemSelected={noop} />
    </div>
  );
};

export default PromoPage;
